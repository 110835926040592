<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        荣誉资质
      </div>
      <div class="topic_desc_en">
        HONOR AND RECOGNITION
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="q_line" v-for="lItem in qPage" :key="lItem">
        <div class="q_item" v-for="(qItem,qIdx) in getArrByPage(lItem)" :key="qIdx">
          <div class="q_card">
            <img :src="qItem.pic">
          </div>
          <div class="q_name">
            {{qItem.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="h_content">
      <div class="main_panel_h">
        <div class="h_line" v-for="lItem in hPage" :key="lItem">
          <div class="h_item" v-for="(hItem,hIdx) in getArrByPageH(lItem)" :key="hIdx">
            <div class="h_card">
              <img :src="hItem.pic">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      qLineSize:4,// 每行个数
      hLineSize:4,// 每行个数

      qualifications:[],
      honors:[]
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    qPage(){
      let _this = this;
      let y = _this.qualifications.length%_this.qLineSize;
      if(y){
        return parseInt(_this.qualifications.length/_this.qLineSize)+1;
      }
      return parseInt(_this.qualifications.length/_this.qLineSize);
    },
    hPage(){
      let _this = this;
      let y = _this.honors.length%_this.hLineSize;
      if(y){
        return parseInt(_this.honors.length/_this.hLineSize)+1;
      }
      return parseInt(_this.honors.length/_this.hLineSize);
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取每页的资质
    getArrByPage(page){
      return this.qualifications.filter((i,idx)=>{
        return idx>=(page-1)*this.qLineSize&&idx<page*this.qLineSize;
      })
    },
    getArrByPageH(page){
      return this.honors.filter((i,idx)=>{
        return idx>=(page-1)*this.hLineSize&&idx<page*this.hLineSize;
      })
    },// 获取页面数据
    getData(){
      let _this = this;
      _this.getDataQualifications();
      _this.getDataHonors();
    },
    // 获取数据 - 资质
    getDataQualifications(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=about&secondaryTitle=qualifications`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.qualifications = configData.map(i=>{
            return Object.assign({},i,{
              pic: `${window.PICSEVERURL}/${i.pic}`
            })
          });
        }else{
          _this.$notify({
            title: '失败',
            message: "资质数据请求失败",
            type: 'error'
          });
        }
      });
    },
    // 获取数据 - 荣誉
    getDataHonors(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=about&secondaryTitle=honors`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"[]";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.honors = configData.map(i=>{
            return Object.assign({},i,{
              pic: `${window.PICSEVERURL}/${i.pic}`
            })
          });
        }else{
          _this.$notify({
            title: '失败',
            message: "荣誉数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getData();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  padding: 0 1.5rem;
  padding-top: 2.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #F6F7F9;
  .topic_panel {
    position: relative;
    margin-bottom: 3rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    width: 100%;
    margin-bottom: 1rem;
    .q_line{
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      .q_item{
        min-width: 50%;
        flex: 1;
        overflow: hidden;
        padding: 0.5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        .q_card{
          flex: 1;
          // background: #FFFFFF;
          box-shadow: 0px 10px 12px 0px rgba(0,0,0,0.1);
          display: flex;
          align-items: center;
          padding: 10px 20px;
          box-sizing: border-box;
          img{
            width: 100%;
          }
        }
        .q_name{
          height: 20px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 0.8rem;
          color: #000000;
          line-height: 1.2rem;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .h_content{
    width: 100%;
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    .main_panel_h{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .h_line{
        margin: 1.5rem 0;
        display: flex;
        flex-wrap: wrap;
        .h_item{
          flex: 1;
          min-width: 50%;
          overflow: hidden;
          // margin-right: 43px;
          padding: 0.5rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          .h_card{
            flex: 1;
            display: flex;
            align-items: center;
            padding: 2px 5px;
            box-sizing: border-box;
            img{
              width: 100%;
            }
          }
        }
      }
      // .main_panel_h_bg{
      //   position: absolute;
      //   top: 0;
      //   left: -0;
      //   pointer-events: none;
      //   width: 608px;
      //   height: 608px;
      //   background: url('./images/奖杯.png') 0 0 no-repeat;
      //   background-size: 80% auto;
      //   transform: translate(-204px, 0);
      // }
    }
  }
}
</style>